import { SINGLE_CTA_PRICE_COMPARISON } from 'lib/@getethos/experiments/constants'
import { useDecisionForFlag } from 'lib/FeaturesAndExperiments/getDecisionForFlag'

export const useSingleCTAPriceComparison = () => {
  const { CONTROL, VARIANT_A, VARIANT_B, VARIANT_C, VARIANT_D } =
    SINGLE_CTA_PRICE_COMPARISON.VARIATIONS
  const { isEnabled, isLoading, variation } = useDecisionForFlag({
    name: SINGLE_CTA_PRICE_COMPARISON.EXPERIMENT_KEY,
    fallbackVariation: CONTROL,
  })

  const isControl = variation === CONTROL
  const isVariantA = isEnabled && variation === VARIANT_A
  const isVariantB = isEnabled && variation === VARIANT_B
  const isVariantC = isEnabled && variation === VARIANT_C
  const isVariantD = isEnabled && variation === VARIANT_D

  let disclosure
  if (isVariantA || isVariantB) {
    disclosure = `Rate comparisons as of 2/20/2025. Comparisons exclude any available riders. Please note variations may exist between policies: some carriers may offer dividends while others don't, and coverage continuation periods may vary.`
  }

  return {
    disclosure,
    isControl,
    isLoading,
    isVariantA,
    isVariantB,
    isVariantC,
    isVariantD,
  }
}
